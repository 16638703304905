<template>
  <template v-if="isMobile">
    <AsyncMobileNaviContainer
      :use-vue-router="false"
      :is-signed-in="isSignedIn"
      :current-user="currentUser"
    />
  </template>
  <template v-else>
    <AsyncDesktopNaviContainer />
  </template>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useBreakpoints } from '@vueuse/core'

import useUserStore from '@/modules/shared/store/useUserStore'

const AsyncDesktopNaviContainer = defineAsyncComponent(() => import('../desktop/components/ContainerWelcome.vue'))
const AsyncMobileNaviContainer = defineAsyncComponent(() => import('../mobile/components/ContainerWelcome.vue'))

const breakpoints = useBreakpoints({
  desktop: 768,
})
const isMobile = breakpoints.smaller('desktop')

const { currentUser, isSignedIn } = useUserStore()
</script>

<style>
/* 實作動態載入所必要的 style 區塊，否則會沒有半行 style 的內容被編譯，導致 manifest.json 裡面沒有該 entry 的 style tag */
body {}
</style>
